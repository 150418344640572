<template>
  <div id="Opppryunities">
    <div class="banner-con">
      <div class="top-left-con">
        <div class="line-one" v-visible="false"></div>
        <div class="line-two" v-visible="false"></div>
        <div class="line-three"></div>
        <p class="bg-tit1">
          {{ $t('Jobs.tit1') }}
        </p>
        <p class="bg-tit2">
          {{ $t('Jobs.tit2') }}
          <span>
            <img style="width: 90px" src="../assets/img/2-Icons/icons_connect-wh.svg" alt="" />
          </span>
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper" ref="mySwiper">
        <swiper-slide v-for="(item, index) in swiperImg" :key="index" class="swiper-item1">
          <div class="box">
            <img class="img" :src="item.img" alt="" />
          </div>
          <!-- <div class="top-right-con"></div> -->
          <div class="bottom-left-con"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
      </swiper>
      <div class="search">
        <div class="search-input" v-if="!isSelect">
          <el-input :placeholder="$t('Jobs.search')" v-model="search">
            <el-button slot="append" icon="el-icon-search" @click="searchKey()"></el-button>
          </el-input>
        </div>
        <div class="search-select" v-if="!isSelect">
          <el-select v-model="select" :placeholder="$t('CreateProfiles.pleaseselect')" @change="getSelectList()">
            <el-option v-for="item in selectTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <div class="search-select" v-if="!isSelect && select == 'Sports Catergories'">
          <el-select v-model="sportselect" :placeholder="$t('CreateProfiles.pleaseselect')" @change="getSportSelect()">
            <el-option v-for="item in sportType" no-data-text="No Data" :key="item.name_en" :label="item.name" :value="item.name_en"> </el-option>
          </el-select>
        </div>
        <div class="search-select" v-if="!isSelect && select == 'Location'">
          <el-select v-model="locationselect" :placeholder="$t('CreateProfiles.pleaseselect')" @change="getLocationSelect()">
            <el-option v-for="item in areas" no-data-text="No Data" :key="item.id" :label="styleLang == 'cn' ? item.name_zh_tw : item.name_en" :value="item.id"> </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="center-background">
      <div class="center-container" style="max-width: 1200px; margin: 0 auto">
        <div class="create">
          <div class="btn-group">
            <div class="create-btn" @click="createJob()">
              <div>
                {{ $t('Jobs.btn1') }}
              </div>
              <i class="el-icon-circle-plus-outline"></i>
            </div>
            <div class="screening-btn" v-if="isLogin">
              <el-select v-model="screening" filterable @change="getDataList()" :placeholder="$t('CreateProfiles.pleaseselect')">
                <el-option v-for="item in screeningTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="job-list">
          <div class="jobs">
            <div class="opportunities" v-for="(item, index) in jobList" :key="index" @click="clickJob(item)">
              <div
                class="opportunities-bg"
                :style="{
                  background:
                    item.banner_url === 'https://df7ocie544p6c.cloudfront.net/images/default.jpg'
                      ? `url(${defaultImg}) no-repeat 100%/100% !important`
                      : `url(${item.banner_url}) no-repeat 100%/100% !important`,
                }"
              >
                <div class="saved" v-if="isLogin && item.isSaved">
                  <img src="../assets/img/2-Icons/icons_voluntary_coach-w_border.svg" alt="" />
                </div>
                <!-- <div class="opportunities-job"> -->
                <div :id="item.isDatePassed === true ? 'opportunity-disabled' : ''" :class="item.type === 'p' ? 'opportunities-job' : 'opportunities-job opportunities-vol'">
                  {{ item.index }}
                </div>
              </div>
              <div class="opportunities-info">
                <p :id="item.isDatePassed === true ? 'info-con' : ''" class="ihksport">
                  {{ item.organisation_name }}
                </p>
                <p :id="item.isDatePassed === true ? 'info-names' : ''" class="info-active">
                  {{ item.event_name }}
                </p>
                <p class="info-sports">
                  <span>
                    <img class="info-img" src="../assets/img/2-Icons/icons_type-of-sports.svg" alt="" />
                  </span>
                  <span class="info-txt">
                    {{ sportType.find((one) => one.name_en == item.sports_type) && sportType.find((one) => one.name_en == item.sports_type).name }}
                  </span>
                </p>
                <p class="info-date">
                  <span>
                    <img class="info-img" src="../assets/img/2-Icons/icons_date.svg" alt="" />
                  </span>
                  <span class="info-txt">
                    {{ item.starting_date }} -
                    {{ item.ending_date }}
                  </span>
                </p>
                <p class="info-time">
                  <span>
                    <img class="info-img" src="../assets/img/2-Icons/icons_duration.svg" alt="" />
                  </span>
                  <span class="info-txt">
                    {{ item.class_duration_start && item.class_duration_start.slice(0, -3) }} -
                    {{ item.class_duration_end && item.class_duration_end.slice(0, -3) }}
                  </span>
                </p>
                <p class="info-place">
                  <span>
                    <img class="info-img" src="../assets/img/2-Icons/icons_location.svg" alt="" />
                  </span>
                  <span class="info-txt" v-if="styleLang == 'cn'">
                    {{ typeof (item.location && areas.find((one) => one.id == item.location)) !== 'undefined' ? item.location && areas.find((one) => one.id == item.location).name_zh_tw : '' }}
                  </span>
                  <span class="info-txt" v-else>
                    {{ typeof (item.location && areas.find((one) => one.id == item.location)) !== 'undefined' ? item.location && areas.find((one) => one.id == item.location).name_en : '' }}
                  </span>
                </p>
                <p class="info-line"></p>
                <p :id="activeJob.isDatePassed === true ? 'info-con' : ''" class="ihksport">{{ $t('Jobs.placeholder15') }}</p>
                <p style="margin: 10px 0">
                  <button :id="item.isDatePassed === true ? 'target-expired' : ''" style="margin: 5px 0" class="target-btn" v-for="(i, o) in (item.target || '').split(',')" :key="o">
                    {{ i && Target.find((one) => one.value == i).label }}
                  </button>
                </p>
                <p class="post-date">{{ $t('Jobs.post_date') }} : {{ item.post_date }}</p>
                <p class="post-date">{{ $t('Jobs.last_edited') }} : {{ item.last_edited }}</p>
              </div>
            </div>
          </div>
          <el-dialog :width="screenWidth > 480 ? '60%' : '98%'" :visible.sync="dialogVisible1" :show-close="false" top="6vh">
            <div class="dialog-info">
              <div class="dialog-bg">
                <el-image style="width: 100%" :src="activeJob.banner_url" :preview-src-list="[activeJob.banner_url]" />
                <div :id="activeJob.isDatePassed === true ? 'opportunity-disabled' : ''" :class="activeJob.type === 'p' ? 'opportunities-job' : 'opportunities-job opportunities-vol'">
                  {{ activeJob.index }}
                </div>
                <div class="group-btn" v-if="isLogin && !isMyJob && userId !== activeJob.user_id">
                  <el-button v-if="activeJob.isDatePassed === false" class="apply-btn" @click="apply(activeJob.id, activeJob.isApply)">{{
                    activeJob.isApply ? $t('Jobs.unapply') : screening !== 'My apply Jobs' ? $t('Jobs.apply') : $t('Jobs.unapply')
                  }}</el-button>
                  <el-button class="save-btn" v-if="activeJob.isDatePassed === false" @click="save(activeJob.id, activeJob.isSaved)">{{
                    activeJob.isSaved ? $t('Jobs.unsave') : $t('Jobs.save')
                  }}</el-button>
                </div>
                <div class="group-btn" v-if="(isLogin && isMyJob) || userId == activeJob.user_id">
                  <el-button class="apply-btn" @click="edit(activeJob.id)">{{ $t('CreateProfiles.edit') }}</el-button>
                  <!-- <el-button
                                        class="save-btn"
                                        @click="applied(activeJob.id)"
                                    >
                                        Applied My job</el-button
                                    > -->
                </div>
              </div>
              <div class="dialog-txt">
                <div class="txt-tlt">
                  <p :id="activeJob.isDatePassed === true ? 'info-con' : ''" class="ihksport">
                    {{ activeJob.organisation_name }}
                  </p>
                  <p :id="activeJob.isDatePassed === true ? 'info-names' : ''" class="info-active">
                    {{ activeJob.event_name }}
                  </p>
                  <p class="info-event">
                    <!-- {{activeJob.event_type }} -->
                    {{
                      typeof (activeJob.event_type && eventsTypes.find((one) => one.value == activeJob.event_type)) !== 'undefined'
                        ? activeJob.event_type && eventsTypes.find((one) => one.value == activeJob.event_type).label
                        : ''
                    }}
                  </p>
                </div>
                <div class="txt-int">
                  <div class="intbox" v-for="(item, index) in txtInt" :key="index">
                    <div class="int-box">
                      <img class="txt-img" :src="item.icon" alt="" />
                      <div class="txt-font1">
                        {{ item.txt }}
                      </div>
                      <div class="txt-font2" v-if="index == 2">
                        {{ activeJob[item.str] && activeJob[item.str].slice(0, -3) }}
                      </div>
                      <!-- Location CN -->
                      <div class="txt-font2" v-else-if="index == 3 && styleLang == 'cn'">
                        {{
                          typeof (activeJob.location && areas.find((one) => one.id == activeJob.location)) !== 'undefined'
                            ? activeJob.location && areas.find((one) => one.id == activeJob.location).name_zh_tw
                            : ''
                        }}
                      </div>
                      <!-- Location EN -->
                      <div lass="txt-font2" v-else-if="index == 3 && styleLang != 'cn'">
                        {{
                          typeof (activeJob.location && areas.find((one) => one.id == activeJob.location)) !== 'undefined'
                            ? activeJob.location && areas.find((one) => one.id == activeJob.location).name_en
                            : ''
                        }}
                      </div>
                      <div class="txt-font2" v-else-if="index == 4">
                        {{ activeJob[item.str] && sportType.find((one) => one.name_en == activeJob[item.str]).name }}
                      </div>
                      <div class="txt-font2" v-else>
                        {{ activeJob[item.str] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottom-line"></div>
                <div class="txt-info">
                  <div class="info-box">
                    <span class="info-name"> {{ $t('Jobs.placeholder15') }} </span>
                    <span class="info-value">
                      <button :id="activeJob.isDatePassed === true ? 'target-expired' : ''" class="target-btn" v-for="(i, o) in (activeJob.target || '').split(',')" :key="o">
                        {{ i && Target.find((one) => one.value == i).label }}
                      </button>
                    </span>
                  </div>
                  <div class="info-box" v-for="(item, index) in txtInfo" :key="index">
                    <span class="info-name">{{ item.name }}</span>
                    <span class="info-value">
                      <span style="color: blue"> {{ activeJob[item.value] }}</span>
                      <!--Commented for error-->
                      <!-- {{?activeJob[item.value] && item.list.find((one) =>one.value == activeJob[item.value] ).label : activeJob[item.value]}} 
                      typeof (activeJob[item.value] && item.list.find((one) => one.value == activeJob[item.value])) !== 'undefined'
                            ? activeJob[item.value] && item.list.find((one) => one.value == activeJob[item.value]).label
                            : activeJob[item.value]
                      -->
                    </span>
                  </div>
                  <div class="float-right">
                    <p class="post-date">
                      {{ $t('Jobs.post_date') }}:
                      {{ activeJob.post_date }}
                    </p>
                    <p class="post-date">
                      {{ $t('Jobs.last_edited') }}:
                      {{ activeJob.last_edited }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </el-dialog>
          <el-dialog :width="screenWidth > 480 ? '60%' : '98%'" :visible.sync="dialogVisible2" :show-close="false">
            <div class="dialog2">
              <div class="dialog2-tit">
                {{ $t('Jobs.dialog1') }}
              </div>
              <div class="check">
                <el-checkbox class="check-info" size="medium" v-model="applyInfo.have_email">{{ $t('Jobs.email') }}</el-checkbox>
                <el-checkbox class="check-info" size="medium" v-model="applyInfo.have_phone">{{ $t('Jobs.phone') }}</el-checkbox>
              </div>
              <el-input type="textarea" :rows="2" :placeholder="$t('Jobs.dialog3')" v-model="applyInfo.message"> </el-input>
            </div>
            <div style="width: 100%">
              <el-button class="confirm-btn" @click="confirm(activeJob.id)">{{ $t('Jobs.confirm') }}</el-button>
            </div>
          </el-dialog>
          <el-dialog :width="screenWidth > 480 ? '60%' : '98%'" :visible.sync="dialogVisible3" :show-close="false">
            <div class="dialog3">
              <div class="dialog2-tit">{{ $t('Jobs.dialog2') }}:</div>
              <div class="dialog3-info">
                <div
                  class="dialog3-bg"
                  :style="{
                    background: activeJob.banner_url ? `url(${activeJob.banner_url}) no-repeat 100%/100% !important` : `#adadad`,
                  }"
                >
                  <div :id="activeJob.isDatePassed === true ? 'opportunity-disabled' : ''" :class="activeJob.type === 'p' ? 'opportunities-job' : 'opportunities-job opportunities-vol'">
                    <!-- <div class="opportunities-job"> -->
                    {{ activeJob.index }}
                  </div>
                </div>
                <div class="dialog3-tlt">
                  <p :id="activeJob.isDatePassed === true ? 'info-con' : ''" class="ihksport">
                    {{ activeJob.organisation_name }}
                  </p>
                  <p :id="activeJob.isDatePassed === true ? 'info-name' : ''" class="info-active">
                    {{ activeJob.event_name }}
                  </p>
                </div>
                <div class="dialog3-txt">
                  <div class="dialog3-left">
                    <div v-for="(item, index) in txtInt" :key="index">
                      <div class="left-box" v-if="index > 0">
                        <span>
                          <img class="left-img" :src="item.icon" alt="" />
                        </span>
                        <!-- <div class="left-font1">
                                                    {{ item.txt }}
                                                </div> -->
                        <!-- <sapn class="left-font2">
                                                    {{ activeJob[item.str] }}
                                                </sapn> -->
                        <!-- Location CN -->
                        <span class="txt-font2" v-if="index == 3 && styleLang == 'cn'">
                          {{
                            typeof (activeJob.location && areas.find((one) => one.id == activeJob.location)) !== 'undefined'
                              ? activeJob.location && areas.find((one) => one.id == activeJob.location).name_zh_tw
                              : ''
                          }}
                        </span>
                        <!-- Location EN -->
                        <span lass="txt-font2" v-else-if="index == 3 && styleLang != 'cn'">
                          {{
                            typeof (activeJob.location && areas.find((one) => one.id == activeJob.location)) !== 'undefined'
                              ? activeJob.location && areas.find((one) => one.id == activeJob.location).name_en
                              : ''
                          }}
                        </span>
                        <span class="left-font2" v-else-if="index == 4">
                          {{ activeJob[item.str] && sportType.find((one) => one.name_en == activeJob[item.str]).name }}
                        </span>
                        <span class="left-font2" v-else>
                          {{ activeJob[item.str] }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="dialog3-right">
                    <div class="info-box">
                      <span class="info-name">
                        {{ $t('Jobs.placeholder15') }}
                      </span>
                      <span class="info-value">
                        <button :id="activeJob.isDatePassed === true ? 'target-expired' : ''" class="target-btn" v-for="(i, o) in (activeJob.target || '').split(',')" :key="o">
                          {{ i && Target.find((one) => one.value == i).label }}
                        </button>
                      </span>
                    </div>
                    <div class="info-box" v-for="(item, index) in txtInfo" :key="index">
                      <div v-if="index < 3">
                        <span class="info-name">{{ item.name }}</span>
                        <span class="info-value">
                          <!--Commented for error-->
                          <span v-if="item.list">
                            {{ activeJob[item.value] }}
                          </span>
                          <!-- {{item.list?activeJob[item.value] && item.list.find((one) =>one.value == activeJob[item.value] ).label : activeJob[item.value]}} 
                          typeof (activeJob[item.value] && item.list.find((one) => one.value == activeJob[item.value])) !== 'undefined'
                                ? activeJob[item.value] && item.list.find((one) => one.value == activeJob[item.value]).label
                                : activeJob[item.value]-->
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 100%">
              <el-button class="confirm-btn" @click="confirmBtn()">{{ $t('Jobs.confirm') }}</el-button>
            </div>
          </el-dialog>
          <div class="paginationStyle">
            <el-pagination
              v-if="pagination.total > 0"
              background
              @current-change="getList"
              :current-page.sync="pagination.currentPage"
              :page-size="pagination.pagesize"
              layout="prev, pager, next"
              :total="pagination.total"
            >
            </el-pagination>
            <div v-else>
              {{ $t('Jobs.nodata') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getJobList, getSaveList, getMyList, getApplyList, saveJob, unsaveJob, applyJob, unapplyJob, GetArea } from '@/api/opportunities'
import { getSportTypes } from '@/api/coachProfile'
import moment from 'moment'
export default {
  name: 'CoachProfiles',
  data() {
    return {
      screenWidth: window.document.body.clientWidth,
      applyInfo: {
        have_phone: false,
        have_email: false,
        message: '',
      },
      search: '',
      select: '',
      screening: '',
      sportselect: '',
      locationselect: '',
      isSelect: false,
      searchList: {
        lastest: false,
        last_edited: false,
        search: false,
        location: false,
        sports_type: false,
      },
      styleLang: 'cn',
      Target: [
        {
          value: 'Primary School',
          label: this.$t('CreateProfiles.coachingTarget1'),
        },
        {
          value: 'Secondary School',
          label: this.$t('CreateProfiles.coachingTarget2'),
        },
        {
          value: 'Parent-child',
          label: this.$t('CreateProfiles.coachingTarget3'),
        },
        {
          value: 'Elderly',
          label: this.$t('CreateProfiles.coachingTarget4'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      skillLevel: [
        {
          value: 'No Relevant Experiences',
          label: this.$t('Jobs.skillLevel1'),
        },
        {
          value: 'Introductory',
          label: this.$t('Jobs.skillLevel2'),
        },
        {
          value: 'Intermediate',
          label: this.$t('Jobs.skillLevel3'),
        },
        {
          value: 'Advanced',
          label: this.$t('Jobs.skillLevel4'),
        },
        {
          value: 'Professional',
          label: this.$t('Jobs.skillLevel5'),
        },
      ],
      mediumOfInstruction: [
        {
          value: 'Cantonese',
          label: this.$t('Jobs.mediumOfInstruction1'),
        },
        {
          value: 'Putonghua',
          label: this.$t('Jobs.mediumOfInstruction2'),
        },
        {
          value: 'English',
          label: this.$t('Jobs.mediumOfInstruction3'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      eventsTypes: [
        {
          value: 'Regular Training Course',
          label: this.$t('Jobs.eventsTypes1'),
        },
        {
          value: 'One-off Event',
          label: this.$t('Jobs.eventsTypes2'),
        },
      ],
      classFrequency: [
        {
          value: 'Every Monday',
          label: this.$t('Jobs.classFrequency1'),
        },
        {
          value: 'Every Tuesday',
          label: this.$t('Jobs.classFrequency2'),
        },
        {
          value: 'Every Wednesday',
          label: this.$t('Jobs.classFrequency3'),
        },
        {
          value: 'Every Thursday',
          label: this.$t('Jobs.classFrequency4'),
        },
        {
          value: 'Every Friday',
          label: this.$t('Jobs.classFrequency5'),
        },
        {
          value: 'Every Saturday',
          label: this.$t('Jobs.classFrequency6'),
        },
        {
          value: 'Every Sunday',
          label: this.$t('Jobs.classFrequency7'),
        },
        {
          value: 'Everyday',
          label: this.$t('Jobs.classFrequency8'),
        },
        {
          value: 'Every Week',
          label: this.$t('Jobs.classFrequency9'),
        },
        {
          value: 'Every Second Week',
          label: this.$t('Jobs.classFrequency10'),
        },
        {
          value: 'Every Month',
          label: this.$t('Jobs.classFrequency11'),
        },
        {
          value: 'To Be Confirmed',
          label: this.$t('Jobs.classFrequency12'),
        },
      ],
      screeningTypes: [
        {
          value: 'all',
          label: this.$t('Jobs.screeningTypes1'),
        },
        {
          value: 'My Jobs',
          label: this.$t('Jobs.screeningTypes2'),
        },
        {
          value: 'My collection of Jobs',
          label: this.$t('Jobs.screeningTypes3'),
        },
        {
          value: 'My apply Jobs',
          label: this.$t('Jobs.screeningTypes4'),
        },
      ],
      selectTypes: [
        {
          value: 'all',
          label: this.$t('Jobs.screeningTypes1'),
        },
        {
          value: 'Lastest',
          label: this.$t('Jobs.selectTypes1'),
        },
        {
          value: 'Last Edited',
          label: this.$t('Jobs.selectTypes2'),
        },
        {
          value: 'Location',
          label: this.$t('CreateProfiles.placeholder7'),
        },
        {
          value: 'Sports Catergories',
          label: this.$t('Jobs.selectTypes3'),
        },
      ],
      sportType: [],
      swiperOption: {
        loop: true,
        speed: 1500,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      swiperImg: [
        {
          img: require('../assets/img/0-Banner/20211120-146.jpg'),
        },
      ],
      pagination: {
        currentPage: 1, // 当前页码
        pagesize: 9, // 每页显示的行数
        tableData: this.jobList,
        total: 0,
      },
      txtInt: [
        {
          txt: this.$t('Jobs.classSize'),
          icon: require('../assets/img/2-Icons/icons_class-size.svg'),
          str: 'class_size',
        },
        {
          txt: this.$t('Jobs.date'),
          icon: require('../assets/img/2-Icons/icons_date.svg'),
          str: 'starting_date',
        },
        {
          txt: this.$t('CreateProfiles.placeholder6'),
          icon: require('../assets/img/2-Icons/icons_duration.svg'),
          str: 'class_duration_start',
        },
        {
          txt: this.$t('CreateProfiles.placeholder7'),
          icon: require('../assets/img/2-Icons/icons_location.svg'),
          str: 'location',
        },
        {
          txt: this.$t('Jobs.typeSprot'),
          icon: require('../assets/img/2-Icons/icons_type-of-sports.svg'),
          str: 'sports_type',
        },
      ],
      txtInt2: [],
      txtInfo: [
        {
          name: this.$t('Jobs.txtInfo1'),
          value: 'skill_level',
          list: this.skillLevel,
        },
        {
          name: this.$t('Jobs.txtInfo5'),
          value: 'price',
        },
        {
          name: this.$t('Jobs.txtInfo2'),
          value: 'medium_of_instruction',
          list: this.mediumOfInstruction,
        },
        {
          name: this.$t('Jobs.txtInfo3'),
          value: 'class_frequency',
          list: this.classFrequency,
        },
        {
          name: this.$t('CreateProfiles.placeholder4'),
          value: 'description',
          list: null,
        },
        {
          name: this.$t('Jobs.txtInfo4'),
          value: 'other_description',
          list: null,
        },
      ],
      jobList: [],
      activeJob: {},
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      userId: null,
      isLogin: false,
      areas: [],
      isMyJob: false,
      defaultImg: require('../assets/images/default_banner.png'),
    }
  },
  created() {
    this.styleLang = window.localStorage.getItem('locale') || 'cn'
  },
  mounted() {
    this.isLogin = this.checkLogin()
    this.getArea()
    this.getUserId()
    this.getSports()
    this.getList()
    this.txtInfo[0].list = this.skillLevel
    this.txtInfo[2].list = this.mediumOfInstruction
    this.txtInfo[3].list = this.classFrequency
  },
  methods: {
    getArea() {
      let area
      GetArea().then((res) => {
        area = res.data
        area.map((one) => {
          this.areas = this.areas.concat(one.Areas)
        })
      })
    },
    getDataList() {
      if (this.screening == 'all' || this.screening == '') {
        this.isSelect = false
        this.isMyJob = false
        ;(this.searchList = {
          lastest: false,
          last_edited: false,
          search: false,
          location: false,
          sports_type: false,
        }),
          this.getList()
      } else if (this.screening == 'My Jobs') {
        this.isMyJob = true
        this.isSelect = true
        this.getMyJobs()
      } else if (this.screening == 'My collection of Jobs') {
        this.isMyJob = false
        this.isSelect = true
        this.getMySave()
      } else if (this.screening == 'My apply Jobs') {
        this.isMyJob = false
        this.isSelect = true
        this.getMyApply()
      }
    },
    getSelectList() {
      if (this.select == 'all') {
        this.searchList = {
          lastest: false,
          last_edited: false,
          search: false,
          location: false,
          sports_type: false,
        }
        this.getList()
      } else if (this.select == 'Lastest') {
        this.searchList = {
          lastest: true,
          last_edited: false,
          search: false,
          location: false,
          sports_type: false,
        }
        // this.searchList.lastest = true;
        this.getList()
      } else if (this.select == 'Last Edited') {
        this.searchList = {
          lastest: false,
          last_edited: true,
          search: false,
          location: false,
          sports_type: false,
        }
        this.getList()
      }
      //  else if (this.select == "Location") {
      //     (this.searchList = {
      //         lastest: false,
      //         last_edited: false,
      //         search: false,
      //         location: true,
      //         sports_type: false,
      //     }),
      //     this.getList();
      // }
    },
    getSportSelect() {
      this.searchList = {
        lastest: false,
        last_edited: false,
        search: false,
        location: false,
        sports_type: true,
      }
      if (this.sportselect) {
        this.getList()
      }
    },
    getLocationSelect() {
      this.searchList = {
        lastest: false,
        last_edited: false,
        search: false,
        location: true,
        sports_type: false,
      }
      if (this.locationselect) {
        this.getList()
      }
    },
    getMyJobs() {
      let parmas
      if (this.userId) {
        parmas = {
          user_id: this.userId,
        }
      }
      getMyList(this.pagination.currentPage, parmas).then((res) => {
        let arrayList = res.data.data.map((item, index) => {
          const dateToday = moment(new Date(), 'DD-MM-YYYY')
          const dateToCompare = moment(item.starting_date, 'DD-MM-YYYY')
          return dateToday.isSameOrAfter(dateToCompare) ? { ...item, isDatePassed: true } : { ...item, isDatePassed: false }
        })
        this.jobList = arrayList
        this.pagination.total = res.data.total
      })
    },
    getMySave() {
      let parmas
      if (this.userId) {
        parmas = {
          user_id: this.userId,
        }
      }
      getSaveList(this.pagination.currentPage, parmas).then((res) => {
        let arrayList = res.data.data.map((item, index) => {
          const dateToday = moment(new Date(), 'DD-MM-YYYY')
          const dateToCompare = moment(item.starting_date, 'DD-MM-YYYY')
          return dateToday.isSameOrAfter(dateToCompare) ? { ...item, isDatePassed: true } : { ...item, isDatePassed: false }
        })
        this.jobList = arrayList
        this.pagination.total = res.data.total
      })
    },
    getMyApply() {
      let parmas
      if (this.userId) {
        parmas = {
          user_id: this.userId,
        }
      }
      getApplyList(this.pagination.currentPage, parmas).then((res) => {
        let arrayList = res.data.data.map((item, index) => {
          const dateToday = moment(new Date(), 'DD-MM-YYYY')
          const dateToCompare = moment(item.starting_date, 'DD-MM-YYYY')
          return dateToday.isSameOrAfter(dateToCompare) ? { ...item, isDatePassed: true } : { ...item, isDatePassed: false }
        })
        this.jobList = arrayList
        this.pagination.total = res.data.total
      })
    },
    searchKey() {
      if (this.search) {
        this.searchList = {
          lastest: false,
          last_edited: false,
          search: true,
          location: false,
          sports_type: false,
        }
        this.getList()
      }
    },
    getSports() {
      getSportTypes().then((res) => {
        this.sportType = res.data.data
      })
    },
    open() {
      this.$prompt.alert(this.$t('CoachProfiles.please1'), this.$t('CoachProfiles.welcome'), {
        confirmButtonText: this.$t('CoachProfiles.confirm'),
        confirmButtonClass: 'box-btn',
      })
    },
    getUserId() {
      this.userId = window.sessionStorage.getItem('user_id')
    },
    getList() {
      let parmas = {}
      if (this.userId) {
        parmas = {
          user_id: this.userId,
        }
      }
      if (Object.keys(this.searchList).length > 0) {
        if (this.searchList.search && this.search) {
          parmas.search = this.search
        }
        if (this.searchList.location) {
          parmas.location = this.locationselect
        }
        if (this.searchList.sports_type) {
          parmas.sports_type = this.sportselect
        }
        if (this.searchList.last_edited) {
          parmas.sort = 'updatedAt'
        }
        if (this.searchList.lastest) {
          parmas.sort = 'createdAt'
        }
      }
      getJobList(this.pagination.currentPage, parmas).then((res) => {
        let arrayList = res.data.data.map((item, index) => {
          const dateToday = moment(new Date(), 'DD-MM-YYYY')
          const dateToCompare = moment(item.starting_date, 'DD-MM-YYYY')
          return dateToday.isSameOrAfter(dateToCompare) ? { ...item, isDatePassed: true } : { ...item, isDatePassed: false }
        })
        this.jobList = arrayList
        this.pagination.total = res.data.total
      })
    },
    checkLogin() {
      let token = window.sessionStorage.getItem('token')
      return token == null ? false : true
    },
    createJob() {
      this.$router.push('/coach-connect/create-job')
    },
    clickJob(item) {
      this.activeJob = item
      this.dialogVisible1 = true
      // comparing whether the date for job application is already due or not
      const dateToday = moment(new Date(), 'DD-MM-YYYY')
      const dateToCompare = moment(item.starting_date, 'DD-MM-YYYY')
      dateToday.isSameOrAfter(dateToCompare) ? (this.activeJob.isDatePassed = true) : (this.activeJob.isDatePassed = false)
    },
    apply(id, isApply) {
      if (isApply || this.screening === 'My apply Jobs') {
        unapplyJob(id)
          .then((res) => {
            this.dialogVisible1 = false
            this.$message({
              message: 'success',
              type: 'success',
            })
            this.getDataList()
          })
          .catch(() => {
            this.$message({
              message: 'fail',
              type: 'error',
            })
            this.getDataList()
          })
      } else {
        this.dialogVisible1 = false
        this.dialogVisible2 = true
      }
    },
    edit(id) {
      this.$router.push({ path: '/coach-connect/create-job', query: { id: id } })
    },
    applied(id) {},
    confirm(id) {
      this.dialogVisible2 = false
      this.dialogVisible3 = true
      applyJob(id, this.applyInfo).then(
        this.getDataList(),
        (this.applyInfo = {
          have_phone: false,
          have_email: false,
          message: '',
        })
      )
    },
    confirmBtn() {
      this.dialogVisible3 = false
    },
    save(id, isSaved) {
      this.dialogVisible1 = false
      if (!isSaved) {
        saveJob(id).then((res) => {
          console.log(id)
          this.getDataList()
        })
      } else {
        unsaveJob(id).then((res) => {
          console.log(id)

          this.getDataList()
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.box-btn {
  background: #f15922 !important;
  border: #fff;
}
.banner-con {
  position: relative;
  .top-left-con {
    width: 62%;
    height: 500px;
    background: #28327b;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(-15deg) translateX(-9%);
    z-index: 2;
    .line-one {
      width: 338px;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 24%;
      right: 2%;
    }
    .line-two {
      width: 120px;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 42%;
      right: 2%;
    }
    .banner-tit1 {
      width: auto;
      margin-top: 18%;
      margin-left: 2%;
      padding-left: 13%;
      text-align: left;
      font-size: 70px !important;
      color: #fff;
      transform: skew(6deg);
      font-weight: bold;
    }
    .banner-tit2 {
      width: auto;
      margin-top: -2%;
      margin-left: 4%;
      padding-left: 10%;
      text-align: left;
      font-size: 70px !important;
      color: #fff;
      transform: skew(6deg);
      font-weight: bold;
      // font-family: "NotoSansCJKtc-Black";
    }
    .bg-tit1 {
      margin-top: 150px;
      font-size: 75px;
      font-weight: bold;
      line-height: 75px;
      color: #ffffff;
      font-size: 70px !important;
      color: #fff;

      transform: skew(13deg);
    }
    .bg-tit2 {
      margin: 0 auto;
      font-size: 75px;
      font-weight: bold;
      line-height: 75px;
      color: #ffffff;
      font-size: 70px !important;
      color: #fff;

      transform: skew(13deg);
    }
    .more {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: #fff;
      transform: skew(15deg);
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .swiper {
    .swiper-pagination {
      display: none !important;
    }
    height: 620px;
    .swiper-item1 {
      position: relative;
      overflow: hidden;
      .box {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 60%;
        overflow: hidden;
        transform: skew(-15deg) translateX(12%);
        .img {
          position: absolute;
          left: 75px;
          bottom: 0;
          height: 100%;
          width: 100%;
          transform: skew(15deg) translateX(-9%);
          object-fit: cover;
        }
      }
      .top-right-con {
        width: 40%;
        height: 9.1%;
        background: #f15922;
        position: absolute;
        top: 3%;
        right: 0;
        transform: skew(-15deg) translateX(2.5%);
      }
      .bottom-left-con {
        width: 40%;
        height: 15%;
        background: #f15922;
        position: absolute;
        bottom: 5%;
        left: 0;
        transform: skew(-15deg) translateX(-3%);
      }
    }
  }
  .search {
    display: flex;
    width: 80%;
    height: 30%;
    background: #f15922;
    position: absolute;
    bottom: -5%;
    left: 0;
    z-index: 2;
    transform: skew(-15deg) translateX(-3%);
    .search-input {
      width: 342px;
      height: 60px;
      margin: 60px 30px 60px 288px;
      border-radius: 4px;
      transform: skew(15deg) translateX(3%);
    }
    .search-select {
      width: 229px;
      height: 60px;
      margin: 60px 30px 60px 0;
      opacity: 1;
      border-radius: 4px;
      transform: skew(15deg) translateX(3%);
    }
  }
}
.center-background {
  background: url('../assets/img/bg-blue.jpg') 100%/100% repeat;
}
.create {
  width: 95%;
  padding-top: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: end;
  .create-btn {
    width: 400px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 31px;
    // width: 357px;
    height: 65px;
    background: #8f98dc;
    border-radius: 4px;
    font-size: 22px;
    font-weight: 600;
    line-height: 65px;
    color: #ffffff;
    .el-icon-circle-plus-outline {
      font-size: 40px;
      margin-left: 20px;
    }
  }
  .create-btn:hover {
    cursor: pointer;
  }
  .el-select {
    width: 100%;
  }
}
.job-list {
  padding: 100px 0 100px 0;
  .jobs {
    margin: 0 auto;
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .opportunities {
      width: 500px;
      // width: 435px;
      margin-bottom: 66px;
      background: #ffffff;
      box-shadow: 0px 0px 20px #d1d4eb;
      border-radius: 8px;
      position: relative;
      height: 750px;
      text-align: left;
      overflow: hidden;
      .opportunities-bg {
        background-color: #adadad;
        height: 30%;
        position: relative;
      }
      .opportunities-info {
        padding-left: 33px;
        overflow: hidden;
        .info-line {
          margin-top: 33px;
          width: 394px;
          height: 0px;
          border: 1px solid #d1d4eb;
        }
        .info-img {
          width: 18px;
          height: 18px;
        }
        .info-txt {
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
    .opportunities:hover {
      cursor: pointer;
    }
  }
}
.jobs:after {
  content: '';
  width: 458px;
}
.opportunities-job {
  position: absolute;
  bottom: 0;
  width: 1.99rem;
  height: 0.5rem;
  background: #8f98dc;
  transform: skew(-15deg) translateX(-3%);
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: #ffffff;
  padding-left: 10px;
  overflow: hidden;
}
.opportunities-vol {
  background: #f15922;
}
#opportunity-disabled {
  position: absolute;
  bottom: 0;
  width: 1.99rem;
  height: 0.5rem;
  background: #b2beb5 !important;
  transform: skew(-15deg) translateX(-3%);
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: #ffffff;
  padding-left: 10px;
  overflow: hidden;
}
.saved {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
}

.check {
  margin: 35px 0;
  display: flex;
  flex-direction: column;
  .check-info {
    display: flex;
    align-items: center;
    line-height: 24px;
    margin: 10px 0;
    input[type='checkbox'] {
      width: 40px;
      height: 40px;
      background-color: #fff;
      -webkit-appearance: none;
      border: 1px solid #d1d4eb;
      border-radius: 50%;
      outline: none;
    }
    input[type='checkbox']:checked {
      background: #d1d4eb;
    }
    .check-right {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #1d1d1d;
    }
  }
}

.ihksport {
  margin: 33px 0 7px 0;
  // width: 91px;
  text-align: left;
  min-height: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #8f98dc;
}
#info-con {
  margin: 33px 0 7px 0;
  // width: 91px;
  text-align: left;
  min-height: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #b2beb5;
}
.info-active {
  margin-bottom: 24px;
  height: 55px;
  // width: 226px;
  text-align: left;
  min-height: 28px;
  font-size: 25px;
  font-weight: bold;
  line-height: 46px;
  color: #28327b;
}
#info-names {
  margin-bottom: 24px;
  height: 55px;
  // width: 226px;
  text-align: left;
  min-height: 28px;
  font-size: 25px;
  font-weight: bold;
  line-height: 46px;
  color: gray;
}
.target-btn {
  // margin: 10px 0;
  width: 160px;
  height: 30px;
  background: #8f98dc;
  border: 0;
  border-radius: 15px;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  margin-right: 5px;
}
#target-expired {
  width: 160px;
  height: 30px;
  background: #b2beb5;
  border: 0;
  border-radius: 15px;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  margin-right: 5px;
}
.post-date {
  margin: 5px 10px 5px 0;
  // margin-top: 5px;
  text-align: end;
  color: #999;
  font-size: 16px;
}
.dialog-info {
  height: 1000px;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  overflow: scroll;
  .dialog-bg {
    height: 370px;
    background: #adadad;
    position: relative;
    overflow: hidden;
    .group-btn {
      position: absolute;
      right: 30px;
      bottom: 20px;
    }
    .apply-btn {
      width: 200px;
      height: 60px;
      background: #f15922;
      border: 1px solid #f15922;
      border-radius: 4px;
      color: #fff;
    }
    .save-btn {
      width: 200px;
      height: 60px;
      background: #fff;
      border: 1px solid #f15922;
      border-radius: 4px;
      color: #f15922;
    }
  }
  .dialog-txt {
    padding-left: 30px;
    .info-event {
      text-align: left;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: #1d1d1d;
    }
    .txt-int {
      margin-top: 34px;
      display: flex;
      justify-content: space-between;
      padding: 0 50px;
      .intbox {
        width: 20%;
        border-right: 3px solid #d1d4eb;
      }
      .intbox:last-child {
        border-right: 0;
      }
      .int-box {
        position: relative;
        .txt-img {
          width: 40px;
          height: 40px;
        }
        .txt-font1 {
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          line-height: 36px;
          color: #8f98dc;
        }
        .txt-font2 {
          margin-top: 20px;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          line-height: 36px;
          color: #1d1d1d;
        }
        .txt-line {
          width: 0px;
          height: 130px;
          border: 1px solid #d1d4eb;
          position: absolute;
          right: -50px;
          top: 0;
        }
      }
    }
    .bottom-line {
      margin-top: 30px;
      width: 97%;
      height: 0;
      border: 1px solid #d1d4eb;
    }
    .txt-info {
      text-align: left;
      position: relative;
      .info-box {
        margin: 10px 0;
        display: flex;
        .info-name {
          font-size: 20px;
          width: 270px;
          font-weight: 600;
          line-height: 36px;
          color: #8f98dc;
          display: inline-block;
        }
        .info-value {
          font-size: 20px;
          font-weight: 400;
          line-height: 36px;
          color: #1d1d1d;
          flex: 1;
        }
      }
      .float-right {
        position: relative;
        right: 30px;
        bottom: 0;
      }
    }
  }
}
.dialog2 {
  margin: 0 32px;
  padding-top: 64px;
  height: 400px;
  background: #ffffff;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.confirm-btn {
  margin: 50px auto;
  width: 180px;
  height: 60px;
  background: #f15922;
  border: 1px solid #f15922;
  opacity: 1;
  color: #fff;
  border-radius: 4px;
}
.dialog3 {
  height: 706px;
  background: #ffffff;
  margin: 0 32px;
  padding-top: 64px;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  .dialog3-info {
    margin: 31px auto;
    width: 1000px;
    // height: 490px;
    background: #ffffff;
    box-shadow: 0px 0px 15px #d1d4eb;
    .dialog3-bg {
      position: relative;
      width: 1000px;
      height: 218px;
      // background: #adadad;
      border-radius: 8px 8px 0px 0px;
    }
    .dialog3-tlt {
      padding-left: 32px;
    }
    .dialog3-txt {
      padding-left: 32px;
      padding-right: 32px;
      display: flex;
      .dialog3-left {
        width: 40%;
        .left-box {
          margin: 10px 0;
          .left-img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .dialog3-right {
        .info-box {
          margin: 10px 0;
          .info-name {
            font-size: 20px;
            width: 270px;
            font-weight: 600;
            line-height: 36px;
            color: #8f98dc;
            display: inline-block;
          }
          .info-value {
            font-size: 20px;
            font-weight: 400;
            line-height: 36px;
            color: #1d1d1d;
          }
        }
      }
    }
  }
}
.paginationStyle {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0 50px 0;
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #f15922; // 进行修改选中项背景和字体
    color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #f15922;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-textarea__inner {
    height: 193px;
    background: #f8f8f8;
    border-radius: 10px;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #f15922;
    border-color: #f15922;
  }
  .el-checkbox__inner {
    border-radius: 50%;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #f15922;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 480px) {
  #Opppryunities {
    .jobs {
      width: 97%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .opportunities {
        width: 49%;
        overflow: hidden;
      }
      .post-date {
        margin: 0;
      }
      // .person-info {
      //     margin-left: 45%;
      // }
    }
    .swiper {
      height: 500px;
      .swiper-item1 {
        .top-right-con {
          width: 40.5%;
          height: 14%;
          transform: skew(-15deg) translateX(7%);
        }
        .box {
          height: 100%;
          width: 53%;
          .img {
            height: 100%;
            width: 120%;
            right: 0%;
          }
        }
      }
    }
    .top-left-con {
      width: 76%;
      height: 368px;
      top: 0;
      left: -16%;
      .bg-tit1 {
        font-size: 35px !important;
        margin-top: 15% !important;
        padding-left: 30%;
      }
      .bg-tit2 {
        margin-top: 2% !important;
        padding-left: 30% !important;
        font-size: 35px !important;
        padding-left: 0;
      }
    }
    .search {
      width: 100%;
    }
    .search-input {
      width: 300px;
      margin: 30px 10px;
      border-radius: 4px;
      transform: skew(15deg) translateX(3%);
    }
    .search-select {
      width: 200px;
      margin: 30px 10px 30px 0;
      border-radius: 4px;
      transform: skew(15deg) translateX(3%);
    }
    .create-btn {
      font-size: 16px;
      width: auto;
    }
    .dialog-info {
      height: auto;
      overflow: scroll;
    }
    .float-right {
      position: static;
      // right: 30px;
      // bottom: 0;
    }
    .txt-int {
      padding: 0;
    }
  }
}
</style>
